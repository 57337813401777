<template>
  <v-row class="groups-contact">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/groups" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>{{ group.name }}
          </router-link>
        </div>
        <div class="d-flex title-input">
          <v-text-field
            v-model="search"
            placeholder="Search by name, status"
            prepend-inner-icon="mdi-magnify"
            :append-icon="search ? 'mdi-close' : ''"
            @click:append="clearSearch"
            outlined
            dense
            required
            color="input"
            @change="(page = 1), getData()"
          >
          </v-text-field>
          <v-btn to="/campaigns-new" class="white--text ml-5" width="184" height="44" color="secondary">New Compaign</v-btn>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <div v-if="!nothing" class="px-sm-10 py-2 px-2 accent">
          <div v-if="!selected.length">
            <v-btn height="40" class="rounded" text tile elevation="0" @click.stop="openAdd">
              <v-icon class="mr-2">mdi-plus</v-icon> Add contact
            </v-btn>
            <span class="divider-vertical"></span>
            <v-btn height="40" class="rounded" text tile elevation="0" @click.stop="columnModal = !columnModal">
              <v-icon class="mr-2">mdi-format-columns</v-icon> Columns
            </v-btn>
          </div>
          <div v-else class="d-flex justify-space-between">
            <v-btn color="opasity" height="40" class="rounded" text tile elevation="0" @click.stop="selected = []">
              <v-icon class="mr-2">mdi-close</v-icon> {{ selected.length }} Items selected
            </v-btn>
            <v-btn color="error" height="40" class="rounded" text tile elevation="0">Delete selected</v-btn>
          </div>
        </div>
        <v-divider v-if="!nothing"></v-divider>
        <v-card flat height="70vh" min-height="350" v-else-if="nothing" class="pa-3 d-flex align-center justify-center flex-column">
          <v-img max-height="124" contain src="@/assets/img/contact.svg"></v-img>
          <div class="text-center text-h5 font-weight-bold mt-8">Add your first contact or import</div>
          <div class="text-center text-h5 font-weight-bold">contacts list from CSV</div>
          <div class="mt-2 text-center opasity--text">Tip: Group your contacts to make a list of targets for a mailing</div>
          <div class="text-center mt-5">
            <v-btn class="me-3" elevation="0" width="184" height="48" color="secondary" @click="openImport">Import Contact</v-btn>
            <v-btn class="ms-3" elevation="0" width="184" height="48" color="primary" @click="openAdd">Add Contact</v-btn>
          </div>
        </v-card>
        <v-card v-else-if="block" height="70vh" min-height="260" class="pa-3 d-flex align-center justify-center flex-column">
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h5 font-weight-bold mt-8">Looks like there are not prices for your account.</div>
          <div class="text-center text-h5 font-weight-bold">Please, contact support to resolve this issue.</div>
        </v-card>
        <div v-else>
          <v-data-table
            class="p-table"
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="data.result"
            item-key="id"
            :items-per-page="Number(itemsPerPage)"
            :height="`calc(100vh - 270px)`"
            checkbox-color="success"
            v-model="selected"
            show-select
          >
            <template v-slot:top>
              <v-dialog v-model="columnModal" max-width="400">
                <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
                  <div class="text-h5 font-weight-bold mb-5">Columns</div>
                  <v-checkbox v-for="item in vars" :key="item.id" v-model="checkboxSelect" :value="item" hide-details color="success">
                    <template v-slot:label>
                      <div class="f18 font-weight-medium mt-1">{{ item.title }}</div>
                    </template>
                  </v-checkbox>
                  <div class="mt-3 d-flex flex-column align-center">
                    <v-btn width="184" height="48" color="primary" @click="editColumn">Update</v-btn>
                    <v-btn width="184" height="48" text class="mt-3" @click="columnModal = !columnModal">Close</v-btn>
                  </div>
                </v-card>
              </v-dialog>
              <v-dialog v-model="updateContact" max-width="536">
                <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
                  <div class="text-h5 font-weight-bold mb-5">Edit contact</div>
                  <v-text-field
                    v-for="item in vars"
                    :key="item.id"
                    v-model="editedItem[item.name]"
                    :label="item.title"
                    filled
                    outlined
                    dense
                    color="input"
                  ></v-text-field>
                  <div class="mt-3 d-flex flex-column align-center">
                    <v-btn width="184" height="48" color="primary" @click="updateItem()">Save</v-btn>
                    <v-btn width="184" height="48" text class="mt-3" @click="closeDialog">Close</v-btn>
                  </div>
                </v-card>
              </v-dialog>
              <v-dialog v-model="deleteContact" max-width="536">
                <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
                  <div class="text-h5 font-weight-bold">Delete Contact</div>
                  <div class="mt-3 opasity--text">Do you really want to delete {{ editedItem.phone }}?</div>
                  <div class="mt-7">
                    <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="closeDialog">no</v-btn>
                    <v-btn width="120" height="48" color="secondary" @click="deleteItem">yes</v-btn>
                  </div>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn class="rounded" text icon tile elevation="0" @click="openUpdete(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <span class="divider-vertical"></span>
              <v-btn class="rounded" text icon tile elevation="0" @click="openDelete(item)">
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    menu-props="auto"
                    hide-details
                    single-line
                    dense
                    @change="getData"
                  ></v-select>
                </div>
                <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <v-dialog v-model="addContact" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-5">Add new contact</div>
          <div v-for="item in vars" :key="item.id">
            <v-text-field v-model="editedItem[item.name]" :label="item.title" filled outlined dense color="input"></v-text-field>
            <div class="error--text text-caption ml-2 error-msg" v-if="error == 'phone__required' && item.title == 'Phone'">
              Please enter phone number
            </div>
            <div class="error--text text-caption ml-2 error-msg" v-if="error == 'phone__invalid' && item.title == 'Phone'">
              Provided phone number is not valid
            </div>
          </div>
          <div class="mt-3 d-flex flex-column align-center">
            <v-btn width="184" height="48" color="primary" @click="addItem()">Save</v-btn>
            <v-btn width="184" height="48" text class="mt-3" @click="closeDialog">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      search: this.$route.query.src || '',
      itemsPerPage: Number(this.$route.query.page_size) || 10,
      page: Number(this.$route.query.page) || 1,
      editedIndex: -1,
      editedItem: {},
      checkboxSelect: [],
      selected: [],
      load: true,
      block: false,
      nothing: true,
      columnModal: false,
      addContact: false,
      updateContact: false,
      deleteContact: false,
      headers: [],
      statusError: null,
      error: null,
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getGroup');
    this.$store.dispatch('getPagination');
    this.$store.dispatch('getGroupsVars', this.$route.params.groupId);
  },
  methods: {
    notifi(btn) {
      if (btn == 'add') {
        this.$notify({ message: 'New contact add', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'update') {
        this.$notify({ message: 'Contact updete', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'delete') {
        this.$notify({ message: 'Contact delete', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'search400') {
        this.$notify({
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    editColumn() {
      this.headers = [];
      for (var i = 0; i < this.vars.length; i++) {
        for (var j = 0; j < this.checkboxSelect.length; j++) {
          if (this.vars[i] == this.checkboxSelect[j]) {
            this.headers.push({ text: this.vars[i].title, value: this.vars[i].name, sortable: false });
          }
        }
      }
      this.headers.push({ text: 'Actions', value: 'actions', align: 'end', sortable: false });
      this.columnModal = false;
    },
    openImport() {
      this.$router.push(`/groups/${this.$route.params.groupId}`);
      setTimeout(() => {
        document.getElementById('import').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
    },
    openAdd() {
      this.addContact = true;
    },
    openUpdete(item) {
      this.editedIndex = this.data.result.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.updateContact = true;
    },
    openDelete(item) {
      this.editedIndex = this.data.result.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.deleteContact = true;
    },
    closeDialog() {
      this.addContact = false;
      this.updateContact = false;
      this.deleteContact = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
        this.editedIndex = -1;
      });
    },
    async addItem() {
      this.error = null;
      const data = Object.assign({}, this.editedItem);
      const id = this.editedItem.id;
      delete data.id;
      await this.$store
        .dispatch('newGroupsContants', data)
        .then(() => {
          this.closeDialog();
          this.getData();
          this.notifi('add');
        })
        .catch((e) => {
          this.error = e.response.data.error[0];
        });
    },
    async updateItem() {
      const data = Object.assign({}, this.editedItem);
      const id = this.editedItem.id;
      delete data.id;
      await this.$store.dispatch('updateGroupsContantsItem', { data, id }).then(() => {
        this.closeDialog();
        this.getData();
        this.notifi('update');
      });
    },
    async deleteItem() {
      await this.$store.dispatch('deleteGroupsContantsItem', this.editedItem.id).then(() => {
        this.getData();
        this.notifi('delete');
        this.closeDialog();
      });
    },
    async getData() {
      await this.$store
        .dispatch('getGroupsContantsList', {
          pageSize: this.itemsPerPage,
          search: this.search,
          page: this.page,
        })
        .then(() => {
          if (this.data.result.length) {
            this.nothing = false;
          }
          this.block = false;
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'account__not_found') {
        this.$store.dispatch('setGroupsContantsList', {});
        this.block = true;
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setGroupsContantsList', {});
        this.nothing = true;
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  watch: {
    addContact(val) {
      val || this.closeDialog();
    },
    updateContact(val) {
      val || this.closeDialog();
    },
    deleteContact(val) {
      val || this.closeDialog();
    },
    vars() {
      this.checkboxSelect = this.vars;
      this.headers = [];
      for (var key in this.vars) {
        this.headers.push({ text: this.vars[key].title, value: this.vars[key].name, sortable: false });
      }
      this.headers.push({ text: 'Actions', value: 'actions', align: 'end', sortable: false });
    },
  },
  computed: {
    data() {
      return this.$store.getters.contactsList;
    },
    group() {
      return this.$store.getters.group;
    },
    vars() {
      return this.$store.getters.groupsVars;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
  },
  destroyed() {
    this.$store.dispatch('setGroupsContantsList', {});
    this.$store.dispatch('setGroup', {});
    this.$store.dispatch('setGroupsVars', []);
  },
};
</script>

<style lang="scss">
.groups-contact {
  tbody tr {
    height: 52px;
  }
}
</style>
